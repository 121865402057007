<template>
  <RentalsRangeTable />
</template>

<script>
import RentalsRangeTable from '../../components/reports/table.vue';

export default {
  name: 'RentalsRange',
  components: {
    RentalsRangeTable,
  },
};
</script>

<style scoped>

</style>
