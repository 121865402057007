var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-breadcrumbs"),
      _c("v-data-table", {
        attrs: {
          "hide-default-footer": "",
          headers: _vm.headers,
          items: _vm.itemsWithDateBreakdown,
          loading: _vm.loading,
          "show-expand": "",
          "disable-pagination": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("h2", { staticClass: "mx-4 py-4" }, [
                    _vm._v("Rentals Range Report")
                  ]),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", height: "100px" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            required: "",
                                            label: "Start Date",
                                            "prepend-icon": "fa-calendar",
                                            readonly: "",
                                            rules: [
                                              function(v) {
                                                return (
                                                  !!v ||
                                                  "Start Date is required"
                                                )
                                              }
                                            ]
                                          },
                                          model: {
                                            value: _vm.startDateDisplay,
                                            callback: function($$v) {
                                              _vm.startDateDisplay = $$v
                                            },
                                            expression: "startDateDisplay"
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.startDatepicker,
                            callback: function($$v) {
                              _vm.startDatepicker = $$v
                            },
                            expression: "startDatepicker"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function($event) {
                                _vm.startDatepicker = false
                              }
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            required: "",
                                            label: "End Date",
                                            "prepend-icon": "fa-calendar",
                                            readonly: "",
                                            rules: _vm.endDateRules
                                          },
                                          model: {
                                            value: _vm.endDateDisplay,
                                            callback: function($$v) {
                                              _vm.endDateDisplay = $$v
                                            },
                                            expression: "endDateDisplay"
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.endDatepicker,
                            callback: function($$v) {
                              _vm.endDatepicker = $$v
                            },
                            expression: "endDatepicker"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function($event) {
                                _vm.endDatepicker = false
                              }
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-3",
                          attrs: { small: "", color: "primary" },
                          on: { click: _vm.generateReport }
                        },
                        [_vm._v(" Generate ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-3",
                          attrs: { disabled: _vm.hasNoData, small: "" },
                          on: { click: _vm.exportReportAsCsv }
                        },
                        [_vm._v(" Export as CSV ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.id",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.id) + " ")]
              }
            },
            {
              key: "item.company_id",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.company_id) + " ")]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.name) + " ")]
              }
            },
            {
              key: "item.inventory",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.inventory) + " ")]
              }
            },
            _vm._l(_vm.dateHeaders, function(dateHeader) {
              return {
                key: "item." + dateHeader.text,
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        key: dateHeader.text,
                        class: _vm.getOverbookedClass(
                          item.inventory,
                          item[dateHeader.text]
                        )
                      },
                      [_vm._v(" " + _vm._s(item[dateHeader.text]) + " ")]
                    )
                  ]
                }
              }
            }),
            {
              key: "expanded-item",
              fn: function(ref) {
                var headers = ref.headers
                var item = ref.item
                return [
                  _c(
                    "td",
                    {
                      staticClass: "expanded-row",
                      attrs: { colspan: headers.length }
                    },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(item.events, function(event, index) {
                                      return _c(
                                        "tr",
                                        { key: index },
                                        [
                                          _c("td", {
                                            staticClass: "spacer-cell"
                                          }),
                                          _c(
                                            "td",
                                            { attrs: { colspan: "3" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "event-info" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: _vm.getEventLink(
                                                          event.id
                                                        ),
                                                        target: "_blank",
                                                        rel:
                                                          "noopener noreferrer"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getEventNameWithDate(
                                                              event.name,
                                                              event.date
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "event-info--caption"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Company ID: " +
                                                          _vm._s(
                                                            event.company_id
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._l(_vm.dateHeaders, function(
                                            dateHeader
                                          ) {
                                            return _c(
                                              "td",
                                              {
                                                key: dateHeader.text,
                                                attrs: {
                                                  width: _vm.dateHeaderWidth
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      event[dateHeader.text]
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _c("br"),
                  _c("h2", [_vm._v("You don't have any data to show.")]),
                  _c("br")
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }