<template>
  <div>
    <v-breadcrumbs></v-breadcrumbs>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="itemsWithDateBreakdown"
      :loading="loading"
      show-expand
      disable-pagination
    >
      <template v-slot:top>
        <h2 class="mx-4 py-4">Rentals Range Report</h2>
        <v-toolbar flat height="100px">
          <v-menu
            v-model="startDatepicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                required
                v-model="startDateDisplay"
                label="Start Date"
                prepend-icon="fa-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || 'Start Date is required']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="startDatepicker = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer />
          <v-menu
            v-model="endDatepicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                required
                v-model="endDateDisplay"
                label="End Date"
                prepend-icon="fa-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="endDateRules"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="endDatepicker = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer />
          <v-btn
            small
            color="primary"
            class="mx-3"
            @click="generateReport"
          >
            Generate
          </v-btn>
          <v-btn
            :disabled="hasNoData"
            small
            class="mx-3"
            @click="exportReportAsCsv"
          >
            Export as CSV
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.company_id`]="{ item }">
        {{ item.company_id }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
      <!-- <template v-slot:[`item.photo`]="{ item }">
        <v-img
          :src="item.photo"
          max-height="100"
          max-width="100"
        ></v-img>
        img stored in wp_posts table, Attachement model in Laravel
      </template> -->
      <template v-slot:[`item.inventory`]="{ item }">
        {{ item.inventory }}
      </template>
      <template v-for="dateHeader in dateHeaders" v-slot:[`item.${dateHeader.text}`]="{ item }">
        <span :key="dateHeader.text" :class="getOverbookedClass(item.inventory, item[dateHeader.text])"
        >
          {{ item[dateHeader.text] }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="expanded-row">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(event, index) in item.events" :key="index">
                  <td class="spacer-cell" />
                  <td colspan="3">
                    <div class="event-info">
                      <a 
                        :href="getEventLink(event.id)"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ getEventNameWithDate(event.name, event.date) }}
                      </a>
                      <span class="event-info--caption">Company ID: {{ event.company_id }}</span>
                    </div>
                  </td>
                  <td 
                    v-for="dateHeader in dateHeaders"
                    :key="dateHeader.text"
                    :width="dateHeaderWidth"
                  >
                    {{ event[dateHeader.text] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:no-data>
        <br />
        <h2>You don't have any data to show.</h2>
        <br />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import * as Papa from 'papaparse';
import ITEMS from '../../graphql/Items/Items.gql';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(dayjsUTC);

export default {
  data: () => ({
    error: false,
    loading: false,
    startDatepicker: false,
    endDatepicker: false,
    reportStartDate: dayjs( new Date() ).format('YYYY-MM-DD'),
    reportEndDate: dayjs( new Date() ).format('YYYY-MM-DD'),
    defaultHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      // { text: "Photo", value: "photo" },
      { text: 'Inventory', value: 'inventory' },
    ],
    dateHeaders: [],
    dateHeaderWidth: '100px',
    headers: [],
    items: [],
    skipQuery: true,
  }),

  apollo: {
    items: {
      query: ITEMS,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          type: 'rental',
        };
      },
      skip() {
        return this.skipQuery;
      },
    },
  },

  computed: {
    hasNoData() {
      return !this.items.length > 0;
    },
    startDateDisplay() {
      if ('' === this.reportStartDate) {
        return '';
      }

      return dayjs(this.reportStartDate).format('MM/DD/YYYY');
    },
    startDate: {
      get: function() {
        return '' === this.reportStartDate ? '' : this.reportStartDate;
      },
      set: function(dateVal) {
        if ('' === dateVal) {
          return '';
        }

        this.reportStartDate = dayjs( dateVal ).format('YYYY-MM-DD');
      },
    },
    endDateDisplay() {
      if ('' === this.reportEndDate) {
        return '';
      }

      return dayjs(this.reportEndDate).format('MM/DD/YYYY');
    },
    endDate: {
      get: function() {
        return '' === this.reportEndDate ? '' : this.reportEndDate;
      },
      set: function(dateVal) {
        if ('' === dateVal) {
          return '';
        }
        
        this.reportEndDate = dayjs( dateVal ).format('YYYY-MM-DD');
      },
    },
    endDateRules()  {
      return [
        v => !!v || 'End Date is required',
        v => (v && this.endDateIsAfterStartDate()) || 'End Date must be on or after Start Date',
      ];
    },

    itemsWithDateBreakdown() {
      return this.items.map(item => {
        const copy = cloneDeep(item);

        copy.booked = copy.events.reduce((_booked, event) => {
          const eventBookedDateRangeInDays = this.dateRangeInDays(event.date_out, event.date_in);

          for (const dateHeader of this.dateHeaders) {
            const reportKey = dateHeader.text;

            for (let i = 0; i < eventBookedDateRangeInDays; i++) {
              const eventKey = dayjs.utc(event.date_out).add(i, 'days').format('MM/DD/YYYY');
              if (reportKey === eventKey) {
                _booked[reportKey] = _booked[reportKey] ? _booked[reportKey] + event.rentalsBooked : event.rentalsBooked;
              }
            }

          }

          return _booked;
        }, {});

        copy.events = copy.events.map(event => {
          const eventBookedDateRangeInDays = this.dateRangeInDays(event.date_out, event.date_in);

          for (const dateHeader of this.dateHeaders) {
            const reportKey = dateHeader.text;
            copy[reportKey] = copy.booked[reportKey] || 0;

            for (let i = 0; i < eventBookedDateRangeInDays; i++) {
              const eventKey = dayjs.utc(event.date_out).add(i, 'days').format('MM/DD/YYYY');
              event[reportKey] = reportKey === eventKey ? event.rentalsBooked : event[reportKey] || 0;
            }
          }

          return event;
        });

        return copy;
      });
    },
  },

  watch: {},

  created() {
    this.maybeAutoLoadReport();
  },

  methods: {
    getEventNameWithDate(name, date) {
      const formattedDate = dayjs.utc(date).format('MM/DD/YYYY');
      return `${name} (${formattedDate})`;
    },
    getEventLink(id) {
      return `${process.env.VUE_APP_CU_APP_URL}proposals/${id}`;
    },
    maybeAutoLoadReport() {
      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.startDate = this.$route.query.startDate;
        this.endDate = this.$route.query.endDate;
        this.generateReport();
      }
    },

    dateRangeInDays(start = this.startDate, end = this.endDate) {
      return dayjs(end).diff(start, 'days') + 1;
    },
    
    endDateIsAfterStartDate(endDate = this.reportEndDate) {
      return dayjs(endDate).diff(dayjs(this.reportStartDate)) >= 0;
    },
    generateDateHeaders() {
      this.dateHeaders = [];
      for(let i = 0; i < this.dateRangeInDays(); i++) {
        const key = dayjs(this.startDate).add(i, 'days').format('MM/DD/YYYY');
        this.dateHeaders.push({
          text: key,
          value: `${key}`,
          width: this.dateHeaderWidth,
        });
      }
      this.headers = [ ...this.defaultHeaders, ...this.dateHeaders ];
      return this.dateHeaders;
    },
    async generateReport() {
      this.loading = true;

      if ( !this.endDateIsAfterStartDate() ) {
        this.loading = false;
        return;
      }

      this.generateDateHeaders();

      this.$apollo.queries.items.skip = false;
      await this.$apollo.queries.items.refetch();
      this.$apollo.queries.items.skip = true;
      this.loading = false;
    },
    getOverbookedClass(inventory, booked) {
      return booked > inventory ? 'overbooked' : '';
    },
    prepareReportForCsv() {
      const rows = [];
      this.itemsWithDateBreakdown.forEach(item => {
        item.events.forEach(event => {
          const formattedDateOut = dayjs.utc(event.date_out).format('MM/DD/YYYY');
          const formattedDateIn = dayjs.utc(event.date_in).format('MM/DD/YYYY');
          const row = {
            'Item': item.name,
            'Event': event.name,
            'Date Out': formattedDateOut,
            'Date In': formattedDateIn,
            'Inventory Qty': item.inventory,
            'Booked Qty': event.rentalsBooked,
          };
          rows.push(row);
        });
      });
      return rows;
    },
    exportReportAsCsv() {
      const rowsForCsv = this.prepareReportForCsv();
      const csvContent = Papa.unparse(rowsForCsv);
      const data = new Blob([ csvContent ], { type: 'text/csv;charset=utf-8;' });
      const filename = this.dateRangeInDays === 1 ? `Rentals_Booked_${this.startDate}.csv` : `Rentals_Booked_${this.startDate}_${this.endDate}.csv`;
      
      const csvLink = document.createElement('a');
      csvLink.setAttribute('href', window.URL.createObjectURL(data));
      csvLink.setAttribute('download', filename);
      document.body.appendChild(csvLink); // Firefox support
      csvLink.click();
      document.body.removeChild(csvLink);
    },
  },

  components: {},
};
</script>

<style scoped>
.overbooked {
  color: red;
  font-weight: 500;
}

.expanded-row {
  padding: 0 !important;
}

.event-info {
  display: flex;
  flex-direction: column;
}

.event-info--caption {
  font-size: 0.75em;
}

.spacer-cell {
  width: 0;
}
</style>