import { render, staticRenderFns } from "./table.vue?vue&type=template&id=512cc6c4&scoped=true&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"
import style0 from "./table.vue?vue&type=style&index=0&id=512cc6c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512cc6c4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBreadcrumbs,VBtn,VDataTable,VDatePicker,VMenu,VSimpleTable,VSpacer,VTextField,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('512cc6c4')) {
      api.createRecord('512cc6c4', component.options)
    } else {
      api.reload('512cc6c4', component.options)
    }
    module.hot.accept("./table.vue?vue&type=template&id=512cc6c4&scoped=true&", function () {
      api.rerender('512cc6c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reports/table.vue"
export default component.exports